<template>
  <v-dialog
    v-model="show"
    width="800"
    persistent
  >
    <v-card>
      <v-card-title
        ref="title"
        class="osis-title-bar"
      >
        <div style="color: white">
          Login
        </div>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          color="white"
          icon
          @click="cancelLogin"
        >
          <v-icon> {{$icons.mdiClose}} </v-icon>
        </v-btn>
      </v-card-title>
      <ErrorAlert
        v-if="notLogedInAlert"
        @close="notLogedInAlert = false"
      >
        Failed
      </ErrorAlert>
      <v-card-text v-if="isSandbox">
        <div class="text-body-1 mt-4">
          This is the login dialog for the OSIS Sandbox.</div>
        <div class="text-body-1 mt-4">
          You can log in with different characters and rights or close this dialog to continue anonymously.
        </div>
      </v-card-text>
      <v-card-text
        class="mt-4"
        v-else-if="termsOfUse"
      >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Terms of Use
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span v-html="termsOfUse"></span>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card class="osis-card">
          <v-card-actions class="justify-center">
            <v-row v-if=!isSandbox>
              <v-col class="d-flex justify-center align-center">
                <v-btn
                  class="osis-button"
                  @click="redirectToBackendLogin"
                >
                  Log in (via Helmholty AAI) and accept Terms of Use
                  <v-icon
                    class="osis-blue"
                    right
                  > {{$icons.mdiLoginVariant}} </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>

        <v-card class="osis-card">
          <v-card-text>
            deprecated soon
            <v-row>
              <v-col
                sm="8"
                class="mx-auto"
              >
                <v-text-field
                  v-model="email"
                  v-on:keyup.enter="login"
                  :rules="[rules.required, rules.email]"
                  label="Email"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  v-on:keyup.enter="login"
                  :append-icon="showPassword ? $icons.mdiEye : $icons.mdiEyeOff"
                  :rules="[rules.required]"
                  :type="showPassword ? 'text' : 'password'"
                  label="Password"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-form ref="form">
              <v-container fluid>
                <v-row v-if=isSandbox>
                  <v-col sm="8">
                    <v-btn
                      class="osis-button"
                      @click="loginAs('dfreundlich@geomar.de')"
                    >
                      Log in as Daniela Freundlich (data manager)
                      <v-icon
                        class="osis-blue"
                        right
                      > {{$icons.mdiLoginVariant}} </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if=isSandbox>
                  <v-col sm="8">
                    <v-btn
                      class="osis-button"
                      @click="loginAs('lschmidt@geomar.de')"
                    >
                      Log in as Lisa Schmidt (expedition editor)
                      <v-icon
                        class="osis-blue"
                        right
                      > {{$icons.mdiLoginVariant}} </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if=isSandbox>
                  <v-col sm="8">
                    <v-btn
                      class="osis-button"
                      @click="loginAs('aklein@geomar.de')"
                    >
                      Log in as Anna Klein (chief scientist)
                      <v-icon
                        class="osis-blue"
                        right
                      > {{$icons.mdiLoginVariant}} </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if=isSandbox>
                  <v-col sm="8">
                    <v-btn
                      class="osis-button"
                      @click="loginAs('sschneider@geomar.de')"
                    >
                      Log in as Susan Schneider (user)
                      <v-icon
                        class="osis-blue"
                        right
                      > {{$icons.mdiLoginVariant}} </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row v-if=!isSandbox>
                  <v-col sm="8">
                    <v-btn
                      class="osis-button"
                      @click="login"
                    >
                      Log in and accept Terms of Use
                      <v-icon
                        class="osis-blue"
                        right
                      > {{$icons.mdiLoginVariant}} </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

              </v-container>
            </v-form>
          </v-card-actions>
        </v-card>

      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import apiRequests from '@/mixins/apiRequests'
import { Buffer } from 'buffer';

export default {
  name: 'LoginDialog',
  mixins: [apiRequests],
  components: {
    ErrorAlert: () => import('@/components/ErrorAlert.vue'),
  },
  data() {
    return {
      isSandbox: false,
      show: true,
      password: '',
      showPassword: false,
      email: '',
      rules: {
        required: value => !!value || 'Required',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail'
        }
      },
      notLogedInAlert: false,
      errorMessage: '',
      termsOfUse: '',
    }
  },
  watch: {
    show: function (newValue) {
      if (!newValue) {
        this.cancelLogin()
      }
    },
    errorMessage: function (newValue) {
      if (newValue) {
        this.notLogedInAlert = true
        this.$refs.title?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
  beforeMount() {
    this.isSandbox = process.env.VUE_APP_IS_SANDBOX === 'true';
    if (!this.isSandbox) { this.getTermsOfView() }
  },
  mounted() {
    let errorMessage = this.$route.query.no_access
    if (errorMessage) {
      this.notLogedInAlert = true
      this.$refs.title?.scrollIntoView({ behavior: 'smooth' });
    }
  },
  methods: {
    cancelLogin() {
      this.notLogedInAlert = false
      this.$store.dispatch('setToken', '')
      this.$store.dispatch('setUserName', '')
      this.$store.dispatch('setUserFullName', '')
      this.$store.dispatch('setUserId', null)
      this.$router.push({ name: 'Home' })
    },
    login() {
      if (this.$refs.form.validate()) {
        this.submitLogin(this.email, this.password)
      }
    },
    redirectToBackendLogin() {
      let url = process.env.VUE_APP_API_URL
      url = url + '/v1/login_via_oidc'
      window.location.href = url
    },
    loginAs(email) {
      if (this.$refs.form.validate()) {
        this.submitLogin(email, 'password')
      }
    },
    async getTermsOfView() {
      const requestParams = {
        method: "GET",
        url: 'terms_of_use'
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 200) {
          this.termsOfUse = await res.json()
        }
      }
      catch (error) {
        console.log(error)
      }
    },
    async submitLogin(email, password) {
      try {
        let base_url = process.env.VUE_APP_API_URL
        let url = ''
        url = base_url + '/v1/login?accept_terms_of_use=true'
        const request = new Request(
          url,
          {
            method: "PUT",
            headers: { 'Authorization': 'Basic ' + Buffer.from(email + ':' + password).toString('base64') }
          }
        )
        const res = await fetch(request)
        if (res.status == 200) {
          const data = await res.json()
          this.$store.dispatch('setToken', data.token)
          this.$store.dispatch('setUserName', data.user_name)
          this.$store.dispatch('setUserFullName', data.full_name)
          this.$store.dispatch('setUserId', data.id)
          this.$router.push({ name: 'MyOsis' })
        }
        else {
          this.notLogedInAlert = true
          this.$refs.title?.scrollIntoView({ behavior: 'smooth' });
        }
      }
      catch (error) {
        console.log(error)
        this.notLogedInAlert = true
        this.$refs.title?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
</script>

<style>
</style>
