<template>
  <div class="mx-14 mt-6">
    <LegOverviewDialog
      ref="legOverviewDialog"
      v-if="showLegDialog"
      :visible="showLegDialog"
      :leg-id="selectedLegId"
      @close="
        showLegDialog = false
        refresh()
      "
    >
    </LegOverviewDialog>
    <EventOverviewDialog
      v-if="showEventDialog"
      :visible="showEventDialog"
      :event-id="selectedEventId"
      :leg-id="selectedEventLegId"
      @close="
        showEventDialog = false;
        refresh()
      "
    >
    </EventOverviewDialog>
    <EventNetworkOverviewDialog
      v-if="showNetworkDialog"
      :visible="showNetworkDialog"
      :event-network-id="selectedNetworkId"
      @close="
        showNetworkDialog = false;
        refresh()
      "
    >
    </EventNetworkOverviewDialog>
    <CruiseOverviewDialog
      v-if="showCruiseDialog"
      :visible="showCruiseDialog"
      :cruise-id="selectedCruiseId"
      @close="
        showCruiseDialog = false;
        refresh()
      "
    >
    </CruiseOverviewDialog>
    <ExperimentOverviewDialog
      v-if="showExperimentDialog"
      :visible="showExperimentDialog"
      :experiment-id="selectedExperimentId"
      @close="
        showExperimentDialog = false;
        refresh()
      "
    >
    </ExperimentOverviewDialog>
    <FileUploadDialog
      v-if="showUploadDialog"
      :visible="showUploadDialog"
      @close="
              showUploadDialog = false;
              refresh();
            "
      :selected-file="selectedFile"
      :selected-method="selectedRestMethod"
    />
    <DeleteDialog
      :visible="showDeleteDialog"
      @delete-confirmed="onDeleteConfirmed"
      @close="showDeleteDialog = false"
      title="Delete File?"
    >
    </DeleteDialog>
    <h4 class="display-1 mb-4">All Files</h4>

    <v-row>
      <v-col sm="3">
        <v-text-field
          v-model="nameSearch"
          hint="Search in Name"
          label="Name"
          persistent-hint
        ></v-text-field>
      </v-col>
      <v-col sm="2">
        <v-autocomplete
          clearable
          v-model="creator.id"
          :loading="creator.loading"
          :items="creator.items"
          item-text="fullname"
          item-value="id"
          :search-input.sync="creator.search"
          hide-no-data
          hint="Type surname to search"
          persistent-hint
          label="Creator"
          append-icon=""
        ></v-autocomplete>
      </v-col>
      <v-col sm="2">
        <v-autocomplete
          clearable
          v-model="uploader.id"
          :loading="uploader.loading"
          :items="uploader.items"
          item-text="fullname"
          item-value="id"
          :search-input.sync="uploader.search"
          hide-no-data
          hint="Type surname to search"
          persistent-hint
          label="Uploader"
          append-icon=""
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-data-table
      class="osis-table elevation-2"
      :headers="headers"
      :items="files"
      :options.sync="options"
      :server-items-length="totalFiles"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="totalFilesItemsPerPage"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
      :expand-icon="$icons.mdiChevronDown"
      item-key="id"
      @item-expanded="loadOldVersion"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <v-tooltip
            top
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-blue mr-2"
                @click="downloadFile(item)"
              >
                {{$icons.mdiDownload}}
              </v-icon>
            </template>
            <span>Download</span>
          </v-tooltip>

          <v-tooltip
            top
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-blue mr-2"
                @click="updateFile(item)"
              >
                {{$icons.mdiUpload}}
              </v-icon>
            </template>
            <span>New Version</span>
          </v-tooltip>
          <v-tooltip
            top
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-orange mr-2"
                @click="editFile(item)"
              >
                {{$icons.mdiPencil}}
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip
            top
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-red mr-2"
                @click="deleteFile(item)"
              >
                {{$icons.mdiDeleteOutline}}
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </v-row>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div
          class="clickable-text linebreak"
          @click="downloadFile(item)"
          style="font-weight: bold;"
        >
          {{ item.name }}
        </div>

      </template>
      <template v-slot:[`item.size`]="{ item }">
        {{ Math.round(item.size / 1024) }}
      </template>
      <template v-slot:[`item.related_to`]="{ item }">
        <div v-if="item.leg">
          <v-chip
            class="osis-chip leg-chip"
            @click="showLeg(item.leg.id)"
          >
            Leg {{ item.leg.name }}
          </v-chip>
        </div>
        <div v-if="item.event">
          <v-chip
            class="osis-chip event-chip"
            @click="showEvent(item.event.id, item.event.leg_id)"
          >
            <div>
              Event {{ item.event.label }}
            </div>
          </v-chip>
        </div>
        <div v-if="item.cruise">
          <v-chip
            class="osis-chip cruise-chip"
            @click="showCruise(item.cruise.id)"
          >
            Cruise {{ item.cruise.label }}
          </v-chip>
        </div>
        <div v-if="item.experiment">
          <v-chip
            class="osis-chip experiment-chip"
            @click="showExperiment(item.experiment.id)"
          >
            <div>
              Experiment {{ item.experiment.label }}
            </div>
          </v-chip>
        </div>
        <div v-if="item.event_network">
          <v-chip
            class="osis-chip network-chip"
            @click="showNetwork(item.event_network.network_id)"
          >
            <div>
              Network {{ item.event_network.networkname }}
            </div>
          </v-chip>
        </div>
      </template>

      <template
        v-slot:expanded-item="{ item }"
        v-if=!loading
      >
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell"
          >

            <v-tooltip
              top
              content-class="osis-tooltip"
              v-if="file.name.length > nameStringLegth"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="no-wrap-cell"
                >
                  {{ shortenString(file.name) }}
                </div>
              </template>
              <span>{{file.name}}</span>
            </v-tooltip>
            <div
              v-else
              class="no-wrap-cell"
            >
              {{ shortenString(file.name) }}
            </div>

          </div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell ml-4"
          >{{ file.fileversion }}</div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell"
          >
            <v-icon v-if="file.openaccess">
              {{$icons.mdiCheck}}
            </v-icon>
            <v-icon v-else>
            </v-icon>
          </div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell"
          >{{ file.extension }}</div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell"
          >{{ Math.round(item.size / 1024) }}</div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell"
          >{{ file.downloads }}</div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell"
          >{{ file.flag }}</div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell"
          >{{ file.created_by }}</div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell"
          >{{ file.uploaded_by }}</div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell"
          >{{ file.date_uploaded }}</div>
        </td>

        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
          ></div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
          ></div>
        </td>
      </template>

      <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
        <div
          v-if="item.fileversion===1"
          class="ml-4"
        >
          {{item.fileversion}}
        </div>
        <div v-else>

          <v-btn
            elevation="1"
            fab
            small
            v-if="item.fileversion>1&&!isExpanded"
            @click="expand(true)"
          >
            <div style="font-size: 0.875rem;">
              {{item.fileversion}}
            </div>
          </v-btn>
          <v-btn
            elevation="1"
            fab
            small
            @click="expand(false)"
            v-if="isExpanded"
          ><v-icon> {{$icons.mdiChevronUp}} </v-icon></v-btn>
        </div>
      </template>

      <template v-slot:[`item.openaccess`]="{ item }">
        <v-icon v-if="item.openaccess">
          {{$icons.mdiCheck}}
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import apiRequests from '@/mixins/apiRequests'
import helperFunctions from '@/mixins/helperFunctions'

export default {
  name: 'TotalFiles',
  components: {
    FileUploadDialog: () => import('@/components/Files/FileUploadDialog.vue'),
    DeleteDialog: () => import('@/components/DeleteDialog.vue'),
    LegOverviewDialog: () => import('@/components/Legs/LegOverviewDialog.vue'),
    EventOverviewDialog: () => import('@/components/Events/EventOverviewDialog.vue'),
    EventNetworkOverviewDialog: () => import('@/components/EventNetworks/EventNetworkOverviewDialog.vue'),
    CruiseOverviewDialog: () => import('@/components/Cruises/CruiseOverviewDialog.vue'),
    ExperimentOverviewDialog: () => import('@/components/Experiments/ExperimentOverviewDialog.vue'),
    DownloadDialog: () => import('@/components/DownloadDialog.vue'),
  },
  mixins: [apiRequests, helperFunctions],
  data() {
    return {
      nameSearch: '',
      creator: {
        id: null,
        loading: false,
        search: '',
        items: []
      },
      uploader: {
        id: null,
        loading: false,
        search: '',
        items: []
      },
      awaitingSearch: false,
      totalFiles: 0,
      files: [],
      abortController: null,
      oldFileVersions: [],
      loading: true,
      showDeleteDialog: false,
      showUploadDialog: false,
      selectedFile: null,
      selectedLegId: null,
      showLegDialog: false,
      selectedEventId: null,
      selectedEventLegId: null,
      showEventDialog: false,
      showNetworkDialog: false,
      selectedNetworkId: false,
      selectedCruiseId: null,
      showCruiseDialog: false,
      selectedExperimentId: null,
      selectedRestMethod: "",
      showExperimentDialog: false,
      nameStringLegth: 20,
      expanded: [],
      singleExpand: true,
      options: {},
      headers: [
        {
          text: 'Filename',
          value: 'name',
        },
        {
          text: 'Version',
          value: 'data-table-expand',
          sortable: false
        },
        {
          text: 'Open Access',
          value: 'openaccess',
        },
        {
          text: 'Ext.',
          value: 'extension',
        },
        {
          text: 'Size [kB]',
          value: 'size',
        },
        {
          text: 'Downloads',
          value: 'downloads',
        },
        {
          text: 'Q-flag',
          value: 'flag',
        },
        {
          text: 'Creator',
          value: 'created_by',
          sortable: false
        },
        {
          text: 'Uploaded by',
          value: 'uploaded_by',
          sortable: false
        },
        {
          text: 'Uploaded at',
          value: 'date_uploaded',
        },
        {
          text: 'Related to',
          value: 'related_to',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],
    }
  },
  watch: {
    nameSearch: {
      handler() {
        this.searchFromApi()
      }
    },
    'options': {
      async handler() {
        await this.getDataFromApi()
      }
    },
    'creator.search': function (val, oldVal) {
      val && val.length > 2 && !val.includes(',') && this.queryCreator(val)
    },
    'creator.id': {
      handler() {
        this.getDataFromApi()
      }
    },
    'uploader.search': function (val, oldVal) {
      val && val.length > 2 && !val.includes(',') && this.queryUploader(val)
    },
    'uploader.id': {
      handler() {
        this.getDataFromApi()
      }
    },
  },
  async mounted() {
  },
  computed: {
    filter_string: function () {
      let filter = {}
      if (this.nameSearch) { filter.name = this.nameSearch }
      if (this.creator.id) { filter.creator_id = this.creator.id }
      if (this.uploader.id) { filter.uploaded_by_id = this.uploader.id }
      return JSON.stringify(filter)
    },
    order_string: function () {
      let order = {}
      if (this.options.sortBy && this.options.sortBy[0]) {
        let direction = "asc"
        if (this.options.sortDesc[0] == true) {
          direction = 'desc'
        }
        order = { [this.options.sortBy[0]]: direction }
      }
      return JSON.stringify(order)
    },
    totalFilesItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("totalFilesItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("totalFilesItemsPerPage", value);
      },
    },
  },
  methods: {
    async refresh() {
      await this.getDataFromApi()
    },
    searchFromApi() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.options.page = 1
          this.getDataFromApi()
          this.awaitingSearch = false
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    async getDataFromApi() {
      this.loading = true
      try {
        let data = await this.getFiles()
        if (data) {
          this.files = data.data
          this.totalFiles = data.meta.total
        }
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    async getFiles() {
      //cancel previuos call
      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()

      const requestParams = {
        method: "GET",
        url: 'files',
        queryParams: {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          filter: this.filter_string,
          order: this.order_string
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams, this.abortController)
        const data = await res.json()
        return data
      } catch (error) {
        // ignore cancelation error
        if (!(error instanceof DOMException)) { console.log(error) }
      }
    },
    async loadOldVersion({ item, value }) {
      if (value)
        await this.getOldFileVerions(item.id)
    },
    shortenString(str) {
      if (!str) return ""
      if (str.length <= this.nameStringLegth) {
        return str;
      } else {
        return str.substring(0, this.nameStringLegth) + '...';
      }
    },
    async getOldFileVerions(fileId) {
      this.loading = true
      const requestParams = {
        method: "GET",
        url: 'files/' + fileId + "/old_versions",
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        this.oldFileVersions = await res.json()
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    async queryCreator(filter) {
      this.creator.loading = true
      this.creator.items = await this.getPersonsNamesFilter(filter)
      this.creator.loading = false
    },
    async queryUploader(filter) {
      this.uploader.loading = true
      this.uploader.items = await this.getPersonsNamesFilter(filter)
      this.uploader.loading = false
    },
    async getPersonsNamesFilter(filter) {
      const requestParams = {
        method: "GET",
        url: 'persons/short',
        queryParams: {
          filter: '{"lastname":"' + filter + '"}'
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        const data = await res.json()
        return data
      } catch (error) {
        console.log(error)
      }
    },
    updateFile(file) {
      this.selectedFile = file
      this.selectedRestMethod = 'PUT'
      this.showUploadDialog = true
    },
    editFile(file) {
      this.selectedFile = file
      this.selectedRestMethod = 'PATCH'
      this.showUploadDialog = true
    },
    deleteFile(file) {
      this.selectedFile = file
      this.selectedRestMethod = ''
      this.showDeleteDialog = true
    },
    async onDeleteConfirmed() {
      let url = 'files/' + this.selectedFile.id

      const requestParams = {
        method: "DELETE",
        url: url,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 204) {
          this.showDeleteDialog = false
          await this.refresh()
        }
        else {
          const data = await res.json()
          this.notSavedAlertText = this.extractFirstErrorMessage(data.message)
        }
      }
      catch (error) {
        console.log(error)
      }
    },
    showLeg(legId) {
      this.selectedLegId = legId
      this.showLegDialog = true
    },
    showEvent(eventId, legId) {
      this.selectedEventId = eventId
      this.selectedEventLegId = legId
      this.showEventDialog = true
    },
    showNetwork(networkId) {
      this.selectedNetworkId = networkId
      this.showNetworkDialog = true
    },
    showCruise(cruiseId) {
      this.selectedCruiseId = cruiseId
      this.showCruiseDialog = true
    },
    showExperiment(experimentId) {
      this.selectedExperimentId = experimentId
      this.showExperimentDialog = true
    },
    async downloadFile(file) {
      let url = process.env.VUE_APP_API_URL
      const request = new Request(
        url + '/v1/files/' + file.id + '/download?accept_terms_of_use=true',
        {
          method: "GET",
          headers: {
            'x-access-token': this.$store.state.token,
            "Content-Type": "application/json"
          },
          credentials: 'include', // Ensure cookies are always sent
        }
      )
      fetch(request)
        .then((res) => { return res.blob() })
        .then((data) => {
          var a = document.createElement("a")
          a.href = window.URL.createObjectURL(data)
          a.download = file.original_filename
          a.click()
          this.showDownloadDialog = false
        })
    },
  },
}
</script>

<style>
</style>